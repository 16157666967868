import { create } from "apisauce";
import packageJson from "../../package.json";

export const createApi = () => {
  const api = create({
    baseURL: process.env.REACT_APP_URL_API,
    headers: {
      "Cache-Control": "no-cache",
      "app-version": packageJson.version,
    },
    timeout: 300000,
  });

  const setToken = (token) => {
    if (token) {
      const headerPrefix = "Bearer ";
      const header = "Authorization";
      api.addRequestTransform((request) => {
        request.headers[header] = `${headerPrefix}${token}`;
        return request;
      });
    }
  };

  // Security
  const login = (email, password) => {
    return api.post(`/api/users/login`, { email, password });
  };

  const validateToken = () => {
    return api.get(`/api/users/validateToken`, {});
  };

  // User
  const getUser = (id) => {
    return api.get(`/api/users/${id}`);
  };

  const getUsers = () => {
    return api.get(`/api/users`);
  };

  const removeUser = (id) => {
    return api.delete(`/api/users/${id}`);
  };

  const saveUser = (user) => {
    return api.post(`/api/users/${user._id}`, user);
  };

  // Employe
  const getEmploye = (id) => {
    return api.get(`/api/employees/${id}`);
  };

  const getEmployees = () => {
    return api.get(`/api/employees`);
  };

  const removeEmploye = (id) => {
    return api.delete(`/api/employees/${id}`);
  };

  const saveEmploye = (employe) => {
    return api.post(`/api/employees/${employe.id}`, employe);
  };

  const createEmploye = (employe) => {
    return api.post(`/api/employees`, employe);
  };

  // Budget
  const getBudgetsByYear = (year) => {
    return api.get(`/api/budgets/year/${year}`);
  };

  const getBudgetData = (teamId, year) => {
    return api.get(`/api/budgets/team/${teamId}/year/${year}`);
  };

  const editBudgetData = (value, employeeId, teamId, teamName, month, year, attr) => {
    return api.post(`/api/budgets/team/${teamId}/year/${year}`, {
      value,
      employeeId,
      teamId,
      teamName,
      month,
      year,
      attr,
    });
  };
  const editBudgetDataMonth = (employees, teamId, teamName, month, year) => {
    return api.post(`/api/budgets/team/${teamId}/year/${year}/month/${month}`, {
      employees,
      teamName,
      teamId,
      month,
      year,
    });
  };

  const importBudget = (data, month, year) => {
    return api.post(`/api/budgets/import`, { data, month, year });
  };

  // return functions for interface
  return {
    setToken,
    login,

    // User
    getUser,
    validateToken,
    getUsers,
    removeUser,
    saveUser,

    // Employe
    getEmploye,
    getEmployees,
    removeEmploye,
    saveEmploye,
    createEmploye,

    // Budget
    getBudgetsByYear,
    getBudgetData,
    editBudgetData,
    editBudgetDataMonth,
    importBudget,
  };
};

// Creates a singleton
export default createApi();
