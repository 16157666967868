import { useState } from "react";
// import api from "../services/api";
import moment from "moment";

export default function EmployeEdit({ employee, index, list, setList, close }) {
  const [employe, setEmploye] = useState({ ...employee });

  const setValue = (key, value) => {
    employe[key] = value;
    setEmploye({ ...employe });
  };

  // const save = async () => {
  //   let data = await api.saveEmploye(employe);

  //   if (data.status === 200) {
  //     list[index] = data.data;
  //     setList([...list]);
  //     close();
  //   }
  // };

  return (
    <div className="modal-edit-employee">
      <div className="modal-header">Edit Employee</div>

      <div className="modal-body">
        <div className="subtitle">Employee Info</div>
        <div className="label-row">
          <label>First Name</label>
          <input value={employe.firstName} onChange={(e) => setValue("firstName", e.target.value)} disabled></input>
        </div>
        <div className="label-row">
          <label>Last Name</label>
          <input value={employe.lastName} onChange={(e) => setValue("lastName", e.target.value)} disabled></input>
        </div>
        <div className="label-row">
          <label>Email</label>
          <input value={employe.email} onChange={(e) => setValue("email", e.target.value)} disabled></input>
        </div>

        {/* BOS */}
        <div className="label-row">
          <label>Employee Id</label>
          <input value={employe.employeeId} onChange={(e) => setValue("employeeId", e.target.value)} disabled></input>
        </div>
        <div className="label-row">
          <label>Position</label>
          <input value={employe.position} onChange={(e) => setValue("position", e.target.value)} disabled></input>
        </div>
        <div className="label-row">
          <label>Location</label>
          <input value={employe.location} onChange={(e) => setValue("location", e.target.value)} disabled></input>
        </div>
        <div className="label-row">
          <label>Team</label>
          <input value={employe.teamName} onChange={(e) => setValue("teamName", e.target.value)} disabled></input>
        </div>
        <div className="label-row">
          <label>Division</label>
          <input value={employe.divisionName} onChange={(e) => setValue("divisionName", e.target.value)} disabled></input>
        </div>
        <div className="label-row">
          <label>Currency</label>
          <input value={employe.currency} onChange={(e) => setValue("currency", e.target.value)} disabled></input>
        </div>

        {/* Financials */}
        <h3 className="mt-30">HISTORY</h3>

        {employe.history.map((h) => (
          <div>
            <div className="label-row">
              <label>Position</label>
              {h.position}
            </div>
            <div className="label-row">
              <label>Start Date</label>
              {h.startDate && moment(h.startDate).format("DD-MM-YYYY")}
            </div>
            <div className="label-row">
              <label>End Date</label>
              {h.endDate && moment(h.endDate).format("DD-MM-YYYY")}
            </div>
            {/* <div className="label-row">
              <label>teamId</label>
              {h.teamId}
            </div> */}
            <div className="label-row">
              <label>Team</label>
              {h.teamName}
            </div>
            <div className="label-row">
              <label>Division</label>
              {h.divisionName}
            </div>
            <div className="label-row">
              <label>Location</label>
              {h.location}
            </div>
            <div className="label-row">
              <label>Currency</label>
              {h.currency}
            </div>
            {/* <div className="label-row">
              <label>creatorId</label>
              {h.creatorId}
            </div> 
            {/* <div className="label-row">
              <label>creatorName</label>
              {h.creatorName}
            </div> 
            <div className="label-row">
              <label>recCreated</label>
              {h.recCreated}
            </div>
            <div className="label-row">
              <label>projectGroupId</label>
              {h.projectGroupId}
            </div>
            <div className="label-row">
              <label>projectGroupName</label>
              {h.projectGroupName}
            </div>
            <div className="label-row">
              <label>payrollLocation</label>
              {h.payrollLocation}
            </div>
            */}
            <hr></hr>
          </div>
        ))}
      </div>

      <div className="modal-footer">
        <div className="btn-bar-left"></div>
        <div className="btn-bar-right">
          {/* <button className="link" onClick={close}>
            Cancel
          </button>
          <button className="green" onClick={save}>
            Update
          </button> */}
          <button className="green" onClick={close}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
}
