import { useEffect, useState } from "react";
import api from "../services/api";
import Table from "../components/Table";
import SidebarRight from "../components/SidebarRight";
import Modal from "../components/Modal";
import UserEdit from "../components/UserEdit";

export default function UserList() {
  const [list, setList] = useState([]);
  const [filter, setFilter] = useState({});
  const [showSidebar, setShowSidebar] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState();
  const [editItem, setEditItem] = useState();
  const [selectedIndex, setSelectedIndex] = useState();

  useEffect(() => {
    const fetchData = async () => {
      let data = await api.getUsers();
      setList(data.data);
    };

    fetchData();
  }, []);

  const config = {
    onClickRow: (item, index) => {
      setEditItem(item);
      setSelectedIndex(index);
      setShowModalEdit(true);
    },
    columns: [
      {
        key: "email",
        header: "Email",
        template: (item) => <span className="main-row">{item.email || "NO EMAIL"}</span>,
      },
      {
        header: "Role",
        key: "role",
      },
    ],
  };

  const setFilterValue = (key) => {
    return (e) => {
      let set = {};
      set[key] = e.target.value;
      setFilter({ ...filter, ...set });
    };
  };

  return (
    <>
      <div className={"page-wrapper" + (showSidebar ? " show-sidebar-right" : "")}>
        <div className="btn-bar">
          <div className="btn-bar-left"></div>
          <div className="btn-bar-right">
            {!showSidebar && (
              <button onClick={() => setShowSidebar(true)}>
                <i className="material-icons">chevron_left</i>
                Filters
              </button>
            )}
          </div>
        </div>

        <div className="table-container">
          <Table config={config} filter={filter} data={list}></Table>
        </div>
      </div>

      <SidebarRight show={showSidebar}>
        <div className="sidebar-header">
          <div className="sidebar-title">Filters</div>
          <div className="sidebar-title-right">
            <button className="medium icon-right" onClick={() => setShowSidebar(false)}>
              Close
              <i className="material-icons">chevron_right</i>
            </button>
          </div>
        </div>
        <div className="filter-container">
          <div className="filter-row">
            <input value={filter.email} placeholder="E-mail" onChange={setFilterValue("email")} />
          </div>
        </div>
        <div className="sidebar-footer">
          <button onClick={() => setFilter({ email: "", firstName: "", lastName: "", teamName: "" })}>
            <i className="material-icons">close</i>
            Reset
          </button>
        </div>
      </SidebarRight>

      {showModalEdit && (
        <Modal close={() => setShowModalEdit(false)}>
          <UserEdit
            userInit={editItem}
            list={list}
            setList={setList}
            index={selectedIndex}
            close={() => setShowModalEdit(false)}
          ></UserEdit>
        </Modal>
      )}
    </>
  );
}
