import { useState } from "react";
import { Helmet } from "react-helmet";
import logo from "../images/logo_main.svg";

// Context
import { useAuth } from "../context/useAuth";
import { useNavigate } from "react-router-dom";

export default function Submit() {
  let { login } = useAuth();
  const [error, setError] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const loginFn = async () => {
    if (!(await login(username, password))) {
      setError(true);
    } else {
      navigate("/employee");
    }
  };

  return (
    <>
      <Helmet>
        <title>HRB</title>
      </Helmet>

      <div className="login-container">
        <img className="login-logo" src={logo} alt="Logo"></img>
        <div className="login-form">
          <input onChange={(e) => setUsername(e.target.value)} placeholder="username"></input>
          <input onChange={(e) => setPassword(e.target.value)} placeholder="password" type="password"></input>
        </div>
        <button className="big green" onClick={() => loginFn()}>
          Login
        </button>

        {error && <div className="error mt-15">Login not valid</div>}
      </div>
    </>
  );
}
