import React, { useState, useContext, createContext } from "react";

// APIs
import api from "../services/api";

export const authContext = createContext();

function useAuthProvider() {
  const [token, setToken] = useState();
  const [user, setUser] = useState();

  const login = async (user, pwd) => {
    let result = await api.login(user, pwd);

    if (result.status === 200 && result.data.token) {
      localStorage.setItem("user_token", result.data.token);
      checkAuth();
      return true;
    } else {
      localStorage.removeItem("user_token");
      return false;
    }
  };

  const logout = async () => {
    localStorage.removeItem("user_token");
    setToken(null);
    setUser(null);
  };

  const checkAuth = async () => {
    let token = localStorage.getItem("user_token");
    if (token) {
      setToken(token);
      api.setToken(token);

      const response = await api.validateToken();

      if (response.status === 200 && response.data) {
        setUser(response.data);
      }
    }
  };

  return {
    token,
    user,
    setToken,
    checkAuth,
    logout,
    login,
  };
}

export function ProvideAuth({ children }) {
  const values = useAuthProvider();
  return <authContext.Provider value={values}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};
