import { useRef, useState } from "react";
import api from "../services/api";
import Papa from "papaparse";
import moment from "moment";

export default function ModalImport({ close }) {
  const [month, setMonth] = useState(moment().format("M"));
  const [year, setYear] = useState(moment().format("YYYY"));
  const [loading, setLoading] = useState();
  const fileReader = new FileReader();
  const inputFile = useRef(null);

  const selectCsv = async () => {
    inputFile.current.click();
  };

  const upload = (e) => {
    e.preventDefault();

    let file = e.target.files[0];
    if (file) {
      setLoading(true);
      fileReader.onload = function (event) {
        let text = event.target.result;

        // Convert _ to .
        text = text.split("\n");
        text[0] = text[0].replace(/_/g, ".");
        text = text.join("\n");

        // Parse JSON
        let array = Papa.parse(text, {
          header: true,
          skipEmptyLines: true,
        });
        inputFile.current.value = null;
        inputFile.current.files = null;

        api.importBudget(array.data, month, year).then((res) => {
          setLoading(false);
          if (res.status === 200) {
            close(res.data);
          } else {
            alert("Error: " + res.data.message);
          }
        });
      };

      fileReader.readAsText(file);
    }
  };
  return (
    <div className="modal-edit-employee">
      <div className="modal-header">Import CSV</div>

      <div className="modal-body">
        <div className="label-row">
          <label> Month</label>
          <select value={month} onChange={(e) => setMonth(e.target.value)}>
            <option value="1">January</option>
            <option value="2">February</option>
            <option value="3">March</option>
            <option value="4">April</option>
            <option value="5">May</option>
            <option value="6">June</option>
            <option value="7">July</option>
            <option value="8">August</option>
            <option value="9">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>
        </div>

        <div className="label-row">
          <label>Year</label>
          <select value={year} onChange={(e) => setYear(e.target.value)}>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
            <option value="2025">2025</option>
            <option value="2026">2026</option>
            <option value="2027">2027</option>
          </select>
        </div>
      </div>

      <div className="modal-footer">
        <div className="btn-bar-left"></div>
        <div className="btn-bar-right">
          <button className="link" onClick={close}>
            Cancel
          </button>
          <button className="green" onClick={() => selectCsv()} disabled={loading} style={{ marginLeft: 15 }}>
            {loading ? "Loading.." : "Import CSV"}
          </button>
          <input type="file" accept=".csv" ref={inputFile} onChange={upload} style={{ display: "none" }} />
        </div>
      </div>
    </div>
  );
}
