import { useState } from "react";
import api from "../services/api";

export default function UserEdit({ userInit, index, list, setList, close }) {
  const [user, setUser] = useState({ ...userInit });

  const setValue = (key, value) => {
    user[key] = value;
    setUser({ ...user });
  };

  const save = async () => {
    let data = await api.saveUser(user);

    if (data.status === 200) {
      list[index] = data.data;
      setList([...list]);
      close();
    }
  };

  return (
    <div className="modal-edit-employee">
      <div className="modal-header">Edit User</div>

      <div className="modal-body">
        <div className="subtitle">User Info</div>
        <div className="label-row">
          <label>Email</label>
          <input value={user.email} onChange={(e) => setValue("email", e.target.value)}></input>
        </div>
        <div className="label-row">
          <label>First Name</label>
          <input value={user.firstName} onChange={(e) => setValue("firstName", e.target.value)}></input>
        </div>
        <div className="label-row">
          <label>Last Name</label>
          <input value={user.lastName} onChange={(e) => setValue("lastName", e.target.value)}></input>
        </div>
      </div>

      <div className="modal-footer">
        <div className="btn-bar-left"></div>
        <div className="btn-bar-right">
          <button className="link" onClick={close}>
            Cancel
          </button>
          <button className="green" onClick={save}>
            Update
          </button>
        </div>
      </div>
    </div>
  );
}
