import { useEffect, useState } from "react";
import api from "../services/api";
import Table from "../components/Table";
import SidebarRight from "../components/SidebarRight";
import moment from "moment";
import Modal from "../components/Modal";
import ModalImport from "../components/ModalImport";

export default function EmployeList() {
  const [list, setList] = useState([]);
  const [year, setYear] = useState(moment().format("YYYY"));
  const [showSidebar, setShowSidebar] = useState(false);
  const [showModalImport, setShowModalImport] = useState(false);

  const fetchData = async () => {
    let data = await api.getBudgetsByYear(year);
    setList(data.data);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [year]);

  const config = {
    linkRow: (item) => {
      return "/budget/" + item.teamId + "/" + item.teamName + "/" + year;
    },
    columns: [
      {
        key: "teamName",
        header: "Team",
        template: (item) => <span className="main-row">{item.teamName}</span>,
      },
    ],
  };

  return (
    <>
      <div className={"page-wrapper" + (showSidebar ? " show-sidebar-right" : "")}>
        <div className="btn-bar">
          <div className="btn-bar-left">
            <button className="link" onClick={() => setShowModalImport(true)}>
              Import CSV
            </button>
          </div>
          <div className="btn-bar-right">
            {!showSidebar && (
              <button onClick={() => setShowSidebar(true)}>
                <i className="material-icons">chevron_left</i>
                Filters
              </button>
            )}
          </div>
        </div>

        <div className="table-container">
          <Table config={config} filter={{}} data={list}></Table>
        </div>
      </div>

      <SidebarRight show={showSidebar}>
        <div className="sidebar-header">
          <div className="sidebar-title">Filters</div>
          <div className="sidebar-title-right">
            <button className="medium icon-right" onClick={() => setShowSidebar(false)}>
              Close
              <i className="material-icons">chevron_right</i>
            </button>
          </div>
        </div>
        <div className="filter-container">
          <select value={year} onChange={(e) => setYear(e.target.value)} style={{ marginLeft: 15 }}>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
            <option value="2025">2025</option>
            <option value="2026">2026</option>
            <option value="2027">2027</option>
          </select>
        </div>
        <div className="sidebar-footer">
          <button onClick={() => setYear(moment().format("YYYY"))}>
            <i className="material-icons">close</i>
            Reset
          </button>
        </div>
      </SidebarRight>

      {showModalImport && (
        <Modal close={() => setShowModalImport(false)} size={"small"}>
          <ModalImport
            close={(result) => {
              if (result) fetchData();
              setShowModalImport(false);
            }}
          ></ModalImport>
        </Modal>
      )}
    </>
  );
}
