import React, { useEffect, useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

// Pages
import Login from "./pages/Login";
import UserList from "./pages/UserList";
import EmployeList from "./pages/EmployeList";
import BugedetList from "./pages/BudgetList";
import BudgetEdit from "./pages/BudgetEdit";

// Context
import { useAuth } from "./context/useAuth";
import Sidebar from "./components/Sidebar";

export default function RoutesApp() {
  let auth = useAuth();
  const location = useLocation();

  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    auth.checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={"main-wrapper" + (auth.user ? " logged" : " hide-sidebar")}>
      <Sidebar></Sidebar>
      <div className="page-container">
        <Routes>
          {auth.user ? (
            <>
              <Route path="/" element={<EmployeList />} />
              <Route path="/employee" element={<EmployeList />} />
              <Route path="/budget" element={<BugedetList />} />
              <Route path="/budget/:teamId/:teamName/:year" element={<BudgetEdit />} />
              <Route path="/user" element={<UserList />} />
            </>
          ) : (
            <Route path="/" element={<Login />} />
          )}
        </Routes>
      </div>
    </div>
  );
}
