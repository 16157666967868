import { useState, useEffect, Fragment } from "react";
import api from "../services/api";
import { useParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import Modal from "../components/Modal";
import BudgetRow from "../components/BudgetRow";

export default function BudgetEdit() {
  const { teamId, teamName, year } = useParams();
  const [budget, setBudget] = useState([]);
  const [showUSD, setShowUSD] = useState(true);
  const [yearFilter, setYearFilter] = useState(year);
  const [highlightedRow, setHighlightedRow] = useState();
  const [highlightedMonth, setHighlightedMonth] = useState();
  const [highlightedYear, setHighlightedYear] = useState();
  const [highlightedColumn, setHighlightedColumn] = useState();
  const [showPopover, setShowPopover] = useState();
  const [editMode, setEditMode] = useState();
  const [copyMode, setCopyMode] = useState();
  const [showOther, setShowOther] = useState();
  const [visibility, setVisibility] = useState({
    salaryPlan: true,
    bonusPlan: true,
    otherPlan: true,
    totalPlan: true,
    salaryActual: true,
    bonusActual: true,
    otherActual: true,
    totalActual: true,
  });

  useEffect(() => {
    fetchBudget();
    // eslint-disable-next-line
  }, [yearFilter]);

  const fetchBudget = async () => {
    let budget = await api.getBudgetData(teamId, yearFilter);
    if (budget.status === 200) {
      setBudget(budget.data);
    }
  };

  const highlightCell = async (rowId, month) => {
    if (copyMode) return;

    setHighlightedMonth(null);

    if (highlightedRow === rowId && highlightedColumn === month) {
      setHighlightedRow(null);
      setHighlightedColumn(null);
    } else {
      setHighlightedRow(rowId);
      setHighlightedColumn(month);
    }
  };

  const changeYear = async (year) => {
    setHighlightedRow(null);
    setHighlightedColumn(null);
    setYearFilter(year);
  };

  const toggleVisibility = async (type) => {
    visibility[type] = !!!visibility[type];
    setVisibility({ ...visibility });
  };

  const changeValue = async (value, employeeId, indexEmployee, month, attr) => {
    if (!budget[indexEmployee].months[month]) budget[indexEmployee].months[month] = {};
    if (attr !== "otherActualMap" && attr !== "otherActualMap_USD") {
      value = parseFloat(value) || 0;
    }
    budget[indexEmployee].months[month][attr] = value;
    setBudget([...budget]);
    saveData(value, employeeId, indexEmployee, teamId, teamName, month, year, attr);
  };

  const saveData = useDebouncedCallback(async (value, employeeId, indexEmployee, teamId, teamName, month, year, attr) => {
    let newBudget = await api.editBudgetData(value, employeeId, teamId, teamName, month + 1, year, attr);
    if (newBudget.data) {
      newBudget.data[indexEmployee].months[month][attr] = budget[indexEmployee].months[month][attr];
      setBudget(newBudget.data);
    }
  }, 300);

  const openModalOther = (indexEmployee, month, fieldNameCurrency) => {
    let fieldMap = "otherActualMap";
    if (fieldNameCurrency.endsWith("_USD")) {
      fieldMap = "otherActualMap_USD";
    }
    setShowOther({ indexEmployee, month, fieldMap });
  };

  const setOtherValue = (otherField, field, value) => {
    console.log(otherField);
    console.log(field);
    console.log(budget[showOther.indexEmployee].months[showOther.month][otherField]);
    budget[showOther.indexEmployee].months[showOther.month][otherField][field] = parseFloat(value) || 0;
    let employeeId = budget[showOther.indexEmployee].id;
    changeValue(
      budget[showOther.indexEmployee].months[showOther.month][otherField],
      employeeId,
      showOther.indexEmployee,
      showOther.month,
      otherField
    );
  };

  const toggleMonthSelected = async (month) => {
    if (!copyMode) {
      // Toggle month
      if (highlightedMonth === month) {
        setHighlightedMonth(null);
      } else {
        setHighlightedMonth(month);
        setHighlightedYear(yearFilter);
      }
      setHighlightedRow(null);
      setHighlightedColumn(null);
    } else {
      // Paste values
      let saveInfo = [];
      for (let e of copyMode) {
        if (budget[e.index]) {
          if (!budget[e.index].months[month]) budget[e.index].months[month] = {};
          budget[e.index].months[month].salaryPlan = e.salaryPlan;
          budget[e.index].months[month].bonusPlan = e.bonusPlan;
          budget[e.index].months[month].otherPlan = e.otherPlan;
        }
        saveInfo.push({
          employeeId: e.id,
          salaryPlan: e.salaryPlan,
          bonusPlan: e.bonusPlan,
          otherPlan: e.otherPlan,
        });
      }

      // Save data
      setBudget([...budget]);
      let newBudget = await api.editBudgetDataMonth(saveInfo, teamId, teamName, month + 1, yearFilter);
      if (newBudget.data) {
        setBudget(newBudget.data);
      }
    }
  };

  const toggleCopyMode = () => {
    if (copyMode) {
      setCopyMode(null);
    } else {
      let copiedValues = [];

      for (let e in budget) {
        let employee = budget[e];
        copiedValues.push({
          index: parseInt(e),
          id: employee.id,
          firstName: employee.firstName,
          lastName: employee.lastName,
          position: employee.position,
          salaryPlan: employee.months[highlightedMonth] ? employee.months[highlightedMonth].salaryPlan : 0,
          bonusPlan: employee.months[highlightedMonth] ? employee.months[highlightedMonth].bonusPlan : 0,
          otherPlan: employee.months[highlightedMonth] ? employee.months[highlightedMonth].otherPlan : 0,
        });
      }

      setCopyMode(copiedValues);
    }
  };

  return (
    <div className="page-wrapper budget-edit-container">
      <div className="btn-bar">
        <div className="btn-bar-left">
          <button className={editMode ? "green" : "link"} onClick={() => setEditMode(!!!editMode)}>
            Edit Plan
          </button>

          <button className={showUSD ? "green" : "link"} onClick={() => setShowUSD(!!!showUSD)}>
            {showUSD ? "Show Original Currency" : "Show USD"}
          </button>

          {(highlightedMonth !== undefined || copyMode) && (
            <button className={copyMode ? "green" : "link"} onClick={() => toggleCopyMode()}>
              {copyMode ? "Quit copy mode" : "Copy values"}
            </button>
          )}
        </div>
        <div className="btn-bar-right"></div>
      </div>

      <div
        className={"table-container" + (copyMode ? " copy-mode" : "") + (editMode ? " edit-mode" : "")}
        style={{ overflow: "hidden" }}
      >
        <div className="btn-bar">
          <div className="btn-bar-left">
            <h3>{teamName}</h3>
          </div>
          <div className="btn-bar-right">
            <button className="link" onClick={() => changeYear(parseInt(yearFilter) - 1)}>
              <i className="material-icons">chevron_left</i>
            </button>
            <div className="year-filter"> {yearFilter}</div>
            <button className="link" onClick={() => changeYear(parseInt(yearFilter) + 1)}>
              <i className="material-icons">chevron_right</i>
            </button>
          </div>
        </div>
        <div className="table-container top-header">
          {showPopover && (
            <div className="popover">
              <div className="popover-row" onClick={() => toggleVisibility("salaryPlan")}>
                <input type="checkbox" checked={visibility.salaryPlan}></input>
                Plan Salary
              </div>
              <div className="popover-row" onClick={() => toggleVisibility("bonusPlan")}>
                <input type="checkbox" checked={visibility.bonusPlan}></input>
                Plan bonus
              </div>
              <div className="popover-row" onClick={() => toggleVisibility("otherPlan")}>
                <input type="checkbox" checked={visibility.otherPlan}></input>
                Plan other
              </div>
              <div className="popover-row" onClick={() => toggleVisibility("totalPlan")}>
                <input type="checkbox" checked={visibility.totalPlan}></input>
                Plan total
              </div>
              <div className="popover-row" onClick={() => toggleVisibility("salaryActual")}>
                <input type="checkbox" checked={visibility.salaryActual}></input>
                Actual Salary
              </div>
              <div className="popover-row" onClick={() => toggleVisibility("bonusActual")}>
                <input type="checkbox" checked={visibility.bonusActual}></input>
                Actual bonus
              </div>
              <div className="popover-row" onClick={() => toggleVisibility("otherActual")}>
                <input type="checkbox" checked={visibility.otherActual}></input>
                Actual other
              </div>
              <div className="popover-row" onClick={() => toggleVisibility("totalActual")}>
                <input type="checkbox" checked={visibility.totalActual}></input>
                Actual total
              </div>
            </div>
          )}
          <table>
            <thead>
              <tr>
                <th className="left">
                  <i className="material-icons" onClick={() => setShowPopover(!!!showPopover)}>
                    more_horizontal
                  </i>
                  <span>Name</span>
                </th>
                <th
                  className={
                    highlightedColumn === 0 || (highlightedMonth === 0 && highlightedYear === yearFilter) ? "selected" : ""
                  }
                  onClick={() => toggleMonthSelected(0)}
                >
                  Jan
                </th>
                <th
                  className={
                    highlightedColumn === 1 || (highlightedMonth === 1 && highlightedYear === yearFilter) ? "selected" : ""
                  }
                  onClick={() => toggleMonthSelected(1)}
                >
                  Feb
                </th>
                <th
                  className={
                    highlightedColumn === 2 || (highlightedMonth === 2 && highlightedYear === yearFilter) ? "selected" : ""
                  }
                  onClick={() => toggleMonthSelected(2)}
                >
                  Mar
                </th>
                <th
                  className={
                    highlightedColumn === 3 || (highlightedMonth === 3 && highlightedYear === yearFilter) ? "selected" : ""
                  }
                  onClick={() => toggleMonthSelected(3)}
                >
                  Apr
                </th>
                <th
                  className={
                    highlightedColumn === 4 || (highlightedMonth === 4 && highlightedYear === yearFilter) ? "selected" : ""
                  }
                  onClick={() => toggleMonthSelected(4)}
                >
                  May
                </th>
                <th
                  className={
                    highlightedColumn === 5 || (highlightedMonth === 5 && highlightedYear === yearFilter) ? "selected" : ""
                  }
                  onClick={() => toggleMonthSelected(5)}
                >
                  Jun
                </th>
                <th
                  className={
                    highlightedColumn === 6 || (highlightedMonth === 6 && highlightedYear === yearFilter) ? "selected" : ""
                  }
                  onClick={() => toggleMonthSelected(6)}
                >
                  Jul
                </th>
                <th
                  className={
                    highlightedColumn === 7 || (highlightedMonth === 7 && highlightedYear === yearFilter) ? "selected" : ""
                  }
                  onClick={() => toggleMonthSelected(7)}
                >
                  Aug
                </th>
                <th
                  className={
                    highlightedColumn === 8 || (highlightedMonth === 8 && highlightedYear === yearFilter) ? "selected" : ""
                  }
                  onClick={() => toggleMonthSelected(8)}
                >
                  Sep
                </th>
                <th
                  className={
                    highlightedColumn === 9 || (highlightedMonth === 9 && highlightedYear === yearFilter) ? "selected" : ""
                  }
                  onClick={() => toggleMonthSelected(9)}
                >
                  Oct
                </th>
                <th
                  className={
                    highlightedColumn === 10 || (highlightedMonth === 10 && highlightedYear === yearFilter) ? "selected" : ""
                  }
                  onClick={() => toggleMonthSelected(10)}
                >
                  Nov
                </th>
                <th
                  className={
                    highlightedColumn === 11 || (highlightedMonth === 11 && highlightedYear === yearFilter) ? "selected" : ""
                  }
                  onClick={() => toggleMonthSelected(11)}
                >
                  Dec
                </th>
                <th
                  className={
                    highlightedColumn === 12 || (highlightedMonth === 12 && highlightedYear === yearFilter) ? "selected" : ""
                  }
                >
                  <b>Total</b>
                </th>
              </tr>
            </thead>
            <tbody>
              {budget.map((employee, indexRow) => {
                const params = {
                  employee,
                  indexRow,
                  visibility,
                  editMode,
                  highlightedRow,
                  highlightedColumn,
                  highlightedMonth,
                  highlightCell,
                  changeValue,
                  showUSD,
                };

                return (
                  <Fragment key={"row_" + indexRow}>
                    <tr className="name-row">
                      <td colSpan={14}>
                        <span className="name-value">
                          {employee.lastName}, {employee.firstName}
                        </span>
                        <span className="role-value">{employee.position}</span>
                      </td>
                    </tr>

                    {/* PLAN */}
                    <BudgetRow field="salary" type="plan" title="Salary/Fee" {...params}></BudgetRow>
                    <BudgetRow field="bonus" type="plan" title="Bonus" {...params}></BudgetRow>
                    <BudgetRow field="other" type="plan" title="Others" {...params}></BudgetRow>
                    <BudgetRow
                      field="total"
                      type="plan"
                      title="Loaded Cost"
                      editable={false}
                      customClass="total-row"
                      {...params}
                    ></BudgetRow>
                    {/* Actual */}

                    <BudgetRow field="salary" type="actual" title="Salary/Fee" {...params}></BudgetRow>
                    <BudgetRow field="bonus" type="actual" title="Bonus" {...params}></BudgetRow>
                    <BudgetRow
                      field="other"
                      type="actual"
                      title="Others"
                      editable={false}
                      openModalOther={openModalOther}
                      {...params}
                    ></BudgetRow>
                    <BudgetRow
                      field="total"
                      type="actual"
                      title="Loaded Cost"
                      editable={false}
                      customClass="total-row"
                      {...params}
                    ></BudgetRow>
                  </Fragment>
                );
              })}
            </tbody>
          </table>

          {showOther && (
            <Modal size={"small"} close={() => setShowOther(false)}>
              <div className="modal-header">Edit Employee</div>

              <div className="modal-body">
                <div className="subtitle">Other costs</div>

                {Object.keys(budget[showOther.indexEmployee].months[showOther.month][showOther.fieldMap]).map((field) => (
                  <div className="label-row" key={"other_" + field}>
                    <label>
                      {field}{" "}
                      {!showOther.fieldMap.endsWith("_USD") &&
                        "(" + budget[showOther.indexEmployee].months[showOther.month].currency + ")"}
                    </label>
                    <span className="align-right">
                      <input
                        readOnly={!editMode}
                        value={budget[showOther.indexEmployee].months[showOther.month][showOther.fieldMap][field]}
                        onChange={(e) => setOtherValue(showOther.fieldMap, field, e.target.value)}
                      ></input>
                    </span>
                  </div>
                ))}

                {/* {editMode &&
                  Object.keys(budget[showOther.indexEmployee].months[showOther.month][showOther.fieldMap]).length === 0 && (
                    <div className="label-row">
                      <label>
                        Other
                        {!showOther.fieldMap.endsWith("_USD") &&
                          "(" + budget[showOther.indexEmployee].months[showOther.month].currency + ")"}
                      </label>
                      <input
                        value={budget[showOther.indexEmployee].months[showOther.month][showOther.fieldMap]["other"]}
                        onChange={(e) => setOtherValue("other", e.target.value)}
                      ></input>
                    </div>
                  )} */}
              </div>

              <div className="modal-footer">
                <div className="btn-bar-left"></div>
                <div className="btn-bar-right">
                  <button className="green" onClick={() => setShowOther(false)}>
                    Done
                  </button>
                </div>
              </div>
            </Modal>
          )}
        </div>
      </div>
    </div>
  );
}
